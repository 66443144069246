
  import { Component, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import CommonBigbutton from "@/components/common/Bigbutton.vue";

  @Component({
    components: {
      CommonBigbutton,
    },
  })
  export default class Main extends Mixins(mixinFixedTaxReduction) {
    go_to_fixed_tax_reduction_employee() {
      this.change_page("fixed_tax_reduction_employee", {
        fixed_tax_reduction_id: this.fixed_tax_reduction_id,
        is_finish_employee: undefined,
      });
    }
  }
