
  import { Component, Mixins } from "vue-property-decorator";
  import mixinFixedTaxReduction from "@/mixins/mixinFixedTaxReduction";
  import CommonProgress from "@/components/common/Progress.vue";
  import Table from "@/components/FixedTaxReduction/master/Table.vue";
  import NoFixedTaxReductionMaster from "@/components/FixedTaxReduction/master/NoFixedTaxReductionMaster.vue";

  @Component({
    components: {
      CommonProgress,
      Table,
      NoFixedTaxReductionMaster,
    },
  })
  export default class Main extends Mixins(mixinFixedTaxReduction) {
    go_to_fixed_tax_reduction_employee() {
      this.change_page("fixed_tax_reduction_employee", {
        fixed_tax_reduction_id: this.fixed_tax_reduction_id,
        is_finish_employee: undefined,
      });
    }
  }
